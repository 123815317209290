<template>
  <div class="">
    <Card class="p-m-2">
      <template #title> Red Edilen Talepler </template>
      <template #content>
        <TalepDataTable v-if="TalepDataTable_render" :columns="columns" :data="TalepList" v-on:set-child-data="getDataChild" :ShowDurum="false" />
      </template>
    </Card>

    <Dialog v-model:visible="TalepModal" :breakpoints="{ '960px': '75vw', '640px': '100vw' }" :style="{ width: '65vw' }"
      :modal="true">
      <template #header>
        <h4>Talep Detay</h4>
      </template>

      <div class="p-grid p-fluid p-formgrid">
        <div class="p-field p-col-4">
          <span for="spanBarkod">Barkod</span>
          <InputText id="Barkod" type="text" v-model="Barkod" aria-labelledby="spanBarkod" disabled />
        </div>

        <div class="p-field p-col-4">
          <span for="spanMusteriAd">Müşteri Adı</span>
          <InputText id="MusteriAd" v-model="MusteriAd" aria-labelledby="spanMusteriAd" disabled/>
        </div>
        <div class="p-field p-col-4">
          <span for="spanOlusturmaTarih">Oluşturma Tarih</span>
          <Calendar id="OlusturmaTarih" disabled v-model="OlusturmaTarih" aria-labelledby="spanOlusturmaTarih" />
        </div>
        <div class="p-field p-col-4">
          <span for="spanTelefonNo">Telefon No</span>
          <InputMask id="TelefonNo" v-model="TelefonNo" mask="(999) 999-9999" placeholder="(___) ___-____" disabled/>
        </div>
        <div class="p-field p-col-4">
          <span for="spanEMail">Müşteri EMail</span>
          <InputText id="EMail" v-model="EMail" aria-labelledby="spanEMail" disabled/>
        </div>
        <div class="p-field p-col-4"></div>

        <div class="p-field p-col-4">
          <span for="spanSehirAd">Şehir</span>
          <Dropdown filter id="SehirAd" v-model="SehirKod" :options="Sehir" optionLabel="SehirAdi"
            optionValue="SehirKod" placeholder="Seçiniz..." @change="CityChange" disabled/>
        </div>
        <div class="p-field p-col-4">
          <span for="spanIlce">İlçe</span>
          <Dropdown filter id="IlceAd" v-model="IlceKod" :options="temp_ilce" optionLabel="IlceAdi"
            optionValue="IlceKod" placeholder="Seçiniz..." disabled />
        </div>
        <div class="p-field p-col-8">
          <span for="spanMusteriAdres">Muşteri Adres</span>
          <Textarea id="MusteriAdres" v-model="MusteriAdres" :autoResize="false" rows="2"
            aria-labelledby="spanMusteriAdres" disabled/>
        </div>
        <div class="p-field p-col-4"></div>
        <div class="p-field p-col-4">
          <span for="spanUrunTur">Ürün Türü</span>
          <Dropdown filter id="UrunTur" v-model="UrunTurKod" :options="UrunTur" optionLabel="TurAd" optionValue="TurKod"
            placeholder="Seçiniz..." disabled/>
        </div>
        <div class="p-field p-col-4">
          <span for="spanUrunMarkaAd">Ürün Marka</span>
          <Dropdown filter id="UrunMarka" v-model="UrunMarkaKod" :options="UrunMarka" optionLabel="MarkaAd"
            optionValue="MarkaKod" placeholder="Seçiniz..." disabled/>
        </div>
        <div class="p-field p-col-4">
          <span for="spanUrunModel">Ürün Model</span>

          <Dropdown filter id="UrunModel" v-model="UrunModelKod" :options="UrunModel" optionLabel="ModelAd"
            optionValue="ModelKod" placeholder="Seçiniz..." disabled/>
        </div>
        <div class="p-field p-col-8">
          <span for="spanArizaAciklama">Arıza Açıklama</span>
          <Textarea id="ArizaAciklama" v-model="ArizaAciklama" :autoResize="false" rows="2"
            aria-labelledby="spanArizaAciklama" disabled/>
        </div>
        <div class="p-field p-col-4"></div>
        <div class="p-field p-col-4">
          <span for="spanTeknikServisAd">Teknik Servis</span>
          <InputText disabled type="text" id="TeknikServisAd" v-model="TeknikServisAd"
            aria-labelledby="spanTeknikServis" />
        </div>
        <div class="p-field p-col-4">
          <span for="spanTalepDurum">Talep Durumu</span>
          <InputText disabled type="text" id="TalepDurumAd" v-model="TalepDurumAd" aria-labelledby="spanTalepDurum" />
        </div>
        <div class="p-field p-col-12">
          <span for="spanRedNedeni">Red Nedeni</span>
          <Textarea id="RedNedeni" v-model="RedNedeni" :autoResize="false" rows="2"
            aria-labelledby="spanRedNedeni" disabled/>
        </div>
      </div>
      <template #footer>
        <ConfirmPopup></ConfirmPopup>
        <Button label="Talebi Tekrar Aç" icon="pi pi-times" class="p-button-sm p-button-info"
          @click="this.TalebiTekrarAc($event)" />
        <Button label="Kapat" icon="pi pi-times" class="p-button-sm p-button-danger"
          @click="this.TalepModal = false;" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import TalepDataTable from "../../DataTable/TalepDataTable.vue";
import GlobalServis from "../../../../services/GlobalServis";

export default {
  data() {
    return {
      TalepDataTable_render: true,
      TalepModal : false,
      TalepList: [],
      columns: [
        { field: "Barkod", header: "Barkod" },
        { field: "MusteriAd", header: "Musteri Adı" },
        { field: "Tarih", header: "Tarih" },
      ],

      SehirIlce: [],
      Sehir: [],
      Ilce: [],
      temp_ilce: [],
      Talep_rowid: null,

      UrunTur: [],
      UrunMarka: [],
      UrunModel: [],

      Barkod: null,
      MusteriAd: null,
      OlusturmaTarih: null,
      TelefonNo: null,
      EMail: null,
      SehirKod: null,
      IlceKod: null,
      MusteriAdres: null,
      UrunTurKod: null,
      UrunMarkaKod: null,
      UrunModelKod: null,
      TeknikServisAd: null,
      ArizaAciklama: null,
      TalepDurumAd: null,

      TeknikServisDisabled: true,
      TeknikServis: [],
      temp_TeknikServis: [],
      TeknikServisKod: null,
    };
  },
  methods: {
    getDataChild(getdata_child) {
      this.TalepModal = !this.TalepModal;
      this.Talep_rowid = getdata_child.data.Talep_rowid;
      let data = {
        Talep_rowid: getdata_child.data.Talep_rowid,
      };
      GlobalServis.GlobalServis("POST", "GetRedEdilenTalepInfo", data).then((res) => {
        if (res.status == 200) {
          this.Barkod = res.data.Talep[0].Barkod;
          this.MusteriAd = res.data.Talep[0].MusteriAd;
          this.OlusturmaTarih = res.data.Talep[0].OlusturmaTarih;
          this.TelefonNo = res.data.Talep[0].TelefonNo;

          this.EMail = res.data.Talep[0].EMail;

          this.SehirKod = res.data.Talep[0].pSehir;
          this.IlceKod = res.data.Talep[0].pIlce;
          this.MusteriAdres = res.data.Talep[0].MusteriAdres;
          this.UrunTurKod = res.data.Talep[0].TurKod;
          this.UrunMarkaKod = res.data.Talep[0].MarkaKod;
          this.UrunModelKod = res.data.Talep[0].ModelKod;
          this.TeknikServisAd = res.data.Talep[0].TeknikServisAd;
          this.TeknikServisKod = res.data.Talep[0].TeknikServisKod;
          this.ArizaAciklama = res.data.Talep[0].ArizaAciklama;
          this.TalepDurumAd = res.data.Talep[0].TalepDurumAd;

          this.RedNedeni = res.data.Talep[0].RedNedeni;

        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
    },
    InitServis() {
      GlobalServis.GlobalServis("GET", "GetRedEdilenTalepTablosu", "data").then((res) => {
        if (res.status == 200) {
          this.TalepList = res.data;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });

      GlobalServis.GlobalServis("GET", "GetSehirIlce", "").then((res) => {
        if (res.status == 200) {
          this.Sehir = res.data.Sehir;
          console.log(this.Sehir);
          this.Ilce = res.data.Ilce;
          this.temp_ilce = res.data.Ilce;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
      GlobalServis.GlobalServis("GET", "GetUrunTurMarkaModel", "").then((res) => {
        if (res.status == 200) {
          this.UrunTur = res.data.Tur;
          this.UrunMarka = res.data.Marka;
          this.UrunModel = res.data.Model;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
      GlobalServis.GlobalServis("GET", "GetTeknikServis", "").then((res) => {
        if (res.status == 200) {
          this.TeknikServis = res.data.TeknikServis;
          this.temp_TeknikServis = res.data.TeknikServis;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
    },
    TalebiTekrarAc(event){
      this.$confirm.require({
        target: event.currentTarget,
        message: "Talebi tekrar açıyorsunuz..",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          let data = {
            Talep_rowid: this.Talep_rowid,
            OnayDurum: true,
          };
          GlobalServis.GlobalServis("POST", "UpdateRedEdilenTalep", data).then(
            (res) => {
              if (res.status == 200 && res.data == "") {
                this.$toast.add({
                  severity: "success",
                  summary: "Başarılı",
                  detail: "Talep tekrar açılmıştır.",
                  life: 3000,
                });
                this.TalepModal = false;
                this.Refresh();
              } else if (res.status == 200 && res.data != "") {
                this.$toast.add({
                  severity: "warn",
                  summary: "Uyarı",
                  detail: res.data,
                  life: 3000,
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Hata",
                  detail: "Hata oldu",
                  life: 3000,
                });
              }
            }
          );
        },
        reject: () => {
          this.$toast.add({
            severity: "info",
            summary: "İptal",
            detail: "İşlem İptal Edildi",
            life: 3000,
          });
        },
      });
    },
    Refresh() {
      this.TalepDataTable_render = false;
      this.$nextTick(() => {
        this.InitServis()
        this.TalepDataTable_render = true;
      });
    },
  },
  mounted() {
    this.InitServis();
  },
  components: {
    TalepDataTable,
  },
};
</script>

<style lang="scss" scoped>
.top-button-area {
  margin-bottom: 5px;
}

.getirbtn {
  margin-top: 20px;
}
</style>